.hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    /* Initial state */
    background-image: url("../assets/img/bg_1.jpg");

    /* Animation */
    animation-name: animate;
    animation-direction: alternate-reverse;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes animate {
    0%, 30% {
        background-image: url("../assets/img/bg_1.jpg");
    }
    33%, 63% {
        background-image: url("../assets/img/bg_2.jpg");
    }
    66%, 100% {
        background-image: url("../assets/img/bg_3.jpg");
    }
}
