@import "tailwindcss/base";
html{
  scroll-behavior: smooth;
}
@import "tailwindcss/components";

@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap')
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCBA08;
}

@font-face {
    font-family: "Header";
    src: url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
  }
  
  @font-face {
    font-family: "Body";
    src: url("../assets/fonts/II-Vorkurs-Medium.ttf");
  }


  .menu .swiper-slide:nth-child(1n) {
    background-image: url("../assets/img/Menu/Menu1.jpeg");
    background-size: cover;
  }
  
  .menu .swiper-slide:nth-child(2n) {
  background-image: url("../assets/img/Menu/Menu2.jpeg");
    background-size: cover;
  }
  
  .menu .swiper-slide:nth-child(3n) {
    background-image: url("../assets/img/Menu/Menu3.jpeg");
    background-size: cover;
  }