@import "tailwindcss/base";
html{
  scroll-behavior: smooth;
}
@import "tailwindcss/components";

@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCBA08;
}

@font-face {
    font-family: "Header";
    src: url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
}

  @font-face {
    font-family: "Body";
    src: url("../assets/fonts/II-Vorkurs-Medium.ttf");
  }


  .catering .swiper-slide:nth-child(1n) {
    background-image: url("../assets/img/Catering_Gallery/1.jpeg");
    background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(2n) {
  background-image: url("../assets/img/Catering_Gallery/2.jpeg");
    background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(3n) {
    background-image: url("../assets/img/Catering_Gallery/3.jpeg");
    background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(4n) {
      background-image: url("../assets/img/Catering_Gallery/4.jpeg");
      background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(5n) {
      background-image: url("../assets/img/Catering_Gallery/5.jpeg");
      background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(6n) {
      background-image: url("../assets/img/Catering_Gallery/6.jpeg");
      background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(7n) {
      background-image: url("../assets/img/Catering_Gallery/7.jpeg");
      background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(8n) {
      background-image: url("../assets/img/Catering_Gallery/8.jpeg");
      background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(9n) {
      background-image: url("../assets/img/Catering_Gallery/9.jpeg");
      background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(10n) {
      background-image: url("../assets/img/Catering_Gallery/10.jpeg");
      background-size: cover;
  }
  
  .catering .swiper-slide:nth-child(11n) {
      background-image: url("../assets/img/Catering_Gallery/11.jpeg");
      background-size: cover;
  }
  
  .idk .swiper-slide:nth-child(1n) {
    background-image: url("../assets/img/Catering_Gallery/2.1.jpg");
    background-size: cover;
  }
  
  .idk .swiper-slide:nth-child(2n) {
    background-image: url("../assets/img/Catering_Gallery/2.2.jpg");
    background-size: cover;
  }
  
  .idk .swiper-slide:nth-child(3n) {
    background-image: url("../assets/img/Catering_Gallery/2.3.jpg");
    background-size: cover;
  }
  
  .idk .swiper-slide:nth-child(4n) {
    background-image: url("../assets/img/Catering_Gallery/2.4.jpg");
    background-size: cover;
  }